<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Step</v-card-title>
        <v-card-text>
          <FormInput
              :value="form['order']"
              label="Order"
              type="number"
              @input="form['order'] = $event"
            />
            <template>
            <FormSelect label="Condition Key" :data="conditions" :value="form['conditionKey']" @input="form['conditionKey'] = $event"></FormSelect>
          </template>
          <template>
            <ActionType label="Action Type" :data="actionTypes" :value="form['actionType']"  :callbackSelect="selectAction" @input="form['actionType'] = $event"></ActionType>
          </template>
          <template>
            <FormInput
            v-if="itemValue"
              :value="form['actionModel']"
              label="Action Model"
              @input="form['actionModel'] = $event"
            />
          </template>
          <template>
            <Model
             v-if="itemValue"
             ref="model"
              :value="form['actionKey']"
              label="Action Key"
              :itemValue=itemValue
              :model="form['actionModel']"
              @input="form['actionKey'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['actionParam']"
              label="Action Param"
              @input="form['actionParam'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/ChapterStep/FormInput";
import FormSelect from "@/components/Moderator/ChapterStep/FormSelect";
import Model from "@/components/Moderator/ChapterStep/Model";
import ActionType from "@/components/Moderator/ChapterStep/ActionType";


import { mapState } from "vuex";
export default {
  props: ["data", "url", "callbackSuccess"],
  components: {
    FormInput,
    FormSelect,
    Model,
    ActionType
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC

    //BOC:[form]
    form: {
      order:1,
      conditionKey: 'success',
      actionType: 'goPage',
      actionKey: null,
      actionModel: 'page',
      actionParam: null,
    },
    fields: [],
    rules: {},
    mobile: null,
    code: null,
    itemValue:'key',
    conditions:[
      {
        name:'Select Condition',
        value:null
      },
      {
        name:'Success',
        value:'success'
      },
      {
        name:'Failed',
        value:'failed'
      },
    ],
    actionTypes:[
      {
        name:'goPage',
        value:'goPage',
        model:'page',
        itemValue:'key',
      },
      {
        name:'restartChapter',
        value:'restartChapter',
        model:'page',
        itemValue:'key',
      },
      {
        name:'goGame',
        value:'goGame',
        model:'miniGame',
        itemValue:'code'
      },
      {
        name:'goItem',
        value:'goItem',
        model:'item',
        itemValue:'key'
      },
      {
        name:'goQuestion',
        value:'goQuestion',
        model:null,
        itemValue:null
      },
    ]
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectAction(val){
      var type = this.$_.find(this.actionTypes, { value: val })
      this.form.actionModel = type.model;
      this.itemValue = type.itemValue
     
      var that = this;
      if(this.itemValue){
        setTimeout(() => {
        that.$refs["model"].fetch(that.form.actionModel);
      }, 100);
      }else{
        this.form.actionKey = null
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      // this.keyRules = [(v) => !!v || "Page key is required"];
      // this.summaryRules = [(v) => !!v || "Page content is required"];

      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>